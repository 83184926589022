// Efeito do menu
$('.clique-menu').hover(function(){
	$('.menu-mobile').slideToggle();
  $('.box-login').fadeOut();
  $('.cliente').removeClass('cliente-ativo');
});

$('.clique-menu').click(function(){
  $('.menu-mobile').slideToggle();
  $('.box-login').fadeOut();
  $('.cliente').removeClass('cliente-ativo');
});

$('.clique-menu').mouseleave(function(){
  $('.menu-mobile').slideUp();
});

// Slide

$('.slider-home').slick({
  dots: false,
  infinite: true,
  arrows: false,
  speed: 300,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '.indicadores'
});



$('.indicadores').slick({
  dots: false,
  infinite: true,
  arrows: false,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 1,
  asNavFor: '.slider-home',
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

// Slider Mobile

$('.slider-mobile').slick({
  prevArrow: $('.prev-mobile'),
  nextArrow: $('.next-mobile')
});

// Cases

 $('.alguns-cases').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  focusOnSelect: true,
   prevArrow: $('.prev-cases'),
   nextArrow: $('.next-cases'),
   responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


 $('.aba-um').click(function(){
    $(this).addClass('aba-ativa');
    $('.aba-dois').removeClass('aba-ativa');
    $('.aba-tres').removeClass('aba-ativa');
    $('.aba-quatro').removeClass('aba-ativa');
    $('.aba-cinco').removeClass('aba-ativa');

    $('.conteudo-aba-um').fadeIn();
    $('.conteudo-aba-dois').hide();
    $('.conteudo-aba-tres').hide();
    $('.conteudo-aba-quatro').hide();
    $('.conteudo-aba-cinco').hide();
 });

$('.aba-dois').click(function(){
    $(this).addClass('aba-ativa');
    $('.aba-um').removeClass('aba-ativa');
    $('.aba-tres').removeClass('aba-ativa');
    $('.aba-quatro').removeClass('aba-ativa');
    $('.aba-cinco').removeClass('aba-ativa');

    $('.conteudo-aba-dois').fadeIn();
    $('.conteudo-aba-um').hide();
    $('.conteudo-aba-tres').hide();
    $('.conteudo-aba-quatro').hide();
    $('.conteudo-aba-cinco').hide();
 });

$('.aba-tres').click(function(){
    $(this).addClass('aba-ativa');
    $('.aba-dois').removeClass('aba-ativa');
    $('.aba-um').removeClass('aba-ativa');
    $('.aba-quatro').removeClass('aba-ativa');
    $('.aba-cinco').removeClass('aba-ativa');

    $('.conteudo-aba-tres').fadeIn();
    $('.conteudo-aba-dois').hide();
    $('.conteudo-aba-um').hide();
    $('.conteudo-aba-quatro').hide();
    $('.conteudo-aba-cinco').hide();
 });

$('.aba-quatro').click(function(){
    $(this).addClass('aba-ativa');
    $('.aba-dois').removeClass('aba-ativa');
    $('.aba-um').removeClass('aba-ativa');
    $('.aba-tres').removeClass('aba-ativa');
    $('.aba-cinco').removeClass('aba-ativa');

    $('.conteudo-aba-quatro').fadeIn();
    $('.conteudo-aba-dois').hide();
    $('.conteudo-aba-tres').hide();
    $('.conteudo-aba-um').hide();
    $('.conteudo-aba-cinco').hide();
 });

$('.aba-cinco').click(function(){
    $(this).addClass('aba-ativa');
    $('.aba-dois').removeClass('aba-ativa');
    $('.aba-um').removeClass('aba-ativa');
    $('.aba-tres').removeClass('aba-ativa');
    $('.aba-quatro').removeClass('aba-ativa');

    $('.conteudo-aba-cinco').fadeIn();
    $('.conteudo-aba-dois').hide();
    $('.conteudo-aba-um').hide();
    $('.conteudo-aba-quatro').hide();
    $('.conteudo-aba-tres').hide();
 });

$(".clique-duvida").click(function(){
  $(this).parent('.item-da-duvida').children('.resposta-da-duvida').slideToggle(function(){
    if( $(this).css('display') == 'block'){
      $(this).parent('.item-da-duvida').children('.clique-duvida').addClass('clique-duvida-ativo');
    } else{
      $(this).parent('.item-da-duvida').children('.clique-duvida').removeClass('clique-duvida-ativo');
    }
  });
});


$('.cliente, .area-responsivo').click(function(){
   $(this).addClass('cliente-ativo');
   $('.box-login').fadeToggle(function(){
    if( $(this).css('display') == 'block'){
      $('.cliente').addClass('cliente-ativo');
    } else{
      $('.cliente').removeClass('cliente-ativo');
    }
   });
   $('.menu-mobile').slideUp();
});

// Maps

function initMap() {

var myLatLng = {lat: -5.807543, lng: -35.20966};

var map = new google.maps.Map(document.getElementById('map'), {
    center: myLatLng,
    zoom: 18,
    scrollwheel: false
  });

var marker = new google.maps.Marker({
    position: myLatLng,
    icon: 'assets/img/pin_soft.png',
    map: map,
    title: 'Softline'
  });

}